import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import ReactModal from "react-modal"
import { Container, Row, Col } from "reactstrap"
import { Helmet } from "react-helmet"
import HeaderSecondary from "../headerSecondary"
import Footer from "../footer"
import OverlayShape from "../../images/overlay-shape-2.svg"
import OverlayBlueShape from "../../images/overlay-shape-3.svg"
import ScreenOne from "../../images/screen-one.png"
import ScreenTwo from "../../images/screen-two.png"
import ScreenThree from "../../images/screen-three.png"
import ScreenFour from "../../images/screen-four.png"
import ScreenFive from "../../images/screen-five.png"
import ShapeOne from "../../images/overlay-shape-1.svg"
import { get } from "lodash"
import { notification } from "antd"
import axios from "axios"
import CloseIcon from "../../images/close.svg"
import "antd/dist/antd.css"
import "../global.scss"
import "./style.scss"
import { signUpUrl } from "../../utils/constants"

const ArrowRight = ({ ...props }) => {
  return (
    <svg
      width={18}
      height={14}
      fill="#C800FF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 7h16m0 0l-6-6m6 6l-6 6" stroke="currentcolor" strokeWidth={2} />
    </svg>
  )
}

class Features extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      requestMlsModal: false,
      MLSFirstName: "",
      MLSLastName: "",
      MLSEmail: "",
      MLSBrokerageName: "",
      yourMLs: "",
      error: {},
      mlsData: [],
      state: [],
      selectedMls: [],
      mls: [],
      successMessage: "",
      selectedState: []
    }
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    // this.requestSent = this.requestSent.bind(this)
  }

  onRequestMLS = () => {
    const emailRegex =
      /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const error = {}
    if (!this.state.MLSFirstName) {
      error.firstName = "Please enter first name"
    }
    if (!this.state.MLSLastName) {
      error.lastName = "Please enter last name"
    }
    if (!this.state.MLSEmail) {
      error.email = "Please enter email"
    } else {
      let testR = emailRegex.test(String(this.state.MLSEmail).toLowerCase())
      if (!testR) {
        error.email = "Please valid email"
      }
    }
    if (!this.state.MLSBrokerageName) {
      error.MLSBrokerageName = "Please enter brokerage name"
    }
    // if(this.state.state.length < 0){
    //   error.state = "Please select state"
    // }
    // if(this.state.mls.length < 0){
    //   error.mls = "Please select mls"
    // }
    if (!this.state.yourMLs) {
      error.yourMLs = "Please enter MLS"
    }
    console.log(error, "error")
    this.setState({ error })
    if (!Object.keys(error).length) {
      const data = {
        firstName: this.state.MLSFirstName,
        lastName: this.state.MLSLastName,
        email: this.state.MLSEmail,
        MLSName: this.state.yourMLs,
        // state: this.state.selectedState.map((data) => (data.value )),
        // mls: this.state.selectedMls.map((data) => ({name: data.value })),
        MLSBrokerageName: this.state.MLSBrokerageName
      }
      axios
        .post("https://api.yardpost.io/api/v1/request/mls", data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          if (response.data.success) {
            setTimeout(() => {
              this.setState({ successMessage: "", requestMlsModal: false })
              // this.requestSent()
            }, 5000)
            this.setState({
              MLSFirstName: "",
              MLSLastName: "",
              MLSEmail: "",
              MLSBrokerageName: "",
              successMessage:
                "Request successfully sent, we'll email you when we have your MLS.",
              yourMLs: "",
              selectedState: [],
              selectedMls: []
            })
          } else {
            console.log(error)
            error.message = "Please try again"
            this.setState({ error })
          }
        })
        .catch(error => {
          console.log(error)
          error.message = "Please try again"
          this.setState({ error })
        })
    }
  }

  handleOpenModal() {
    this.setState({ requestMlsModal: true })
  }

  handleCloseModal() {
    this.setState({
      requestMlsModal: false,
      error: {},
      MLSFirstName: "",
      MLSEmail: "",
      MLSName: "",
      MLSBrokerageName: "",
      MLSLastName: "",
      yourMLs: ""
    })
  }

  // requestSent(){
  //   console.log('notification')
  //   notification['success']({
  //     key: 'updatable',
  //     message: 'Yardpost',
  //     description: 'Your request is sent successfully.'
  //   })
  // }

  render() {
    const {
      gifDurations,
      error,
      selectedMls,
      selectedState,
      successMessage,
      state,
      mls,
      currentSlide
    } = this.state
    return (
      <div className="features-page">
        <Helmet>
          <title>Features</title>
          <meta
            name="description"
            content="A standout web presence, a simple CRM, easy to use marketing tools, and a powerful listing management platform with IDX and marketplace syndication all built in and all in one place."
          />
        </Helmet>
        <HeaderSecondary />
        <section className="features-banner-section">
          <div className="overlay-shape">
            <img className="img-fluid" src={ShapeOne} alt=""/>
          </div>
          <Container>
            <Row>
              <Col>
                <div className="dark-banner-head">
                  <div className="sub-heading">
                    <h1 className="title">Get The Closer's Tool-Kit </h1>
                    <p className="subtitle">
                      Build your brand, showcase your listings and start selling
                      more properties.
                    </p>
                    <a
                      href={signUpUrl}
                      className="btn yp-btn-dark"
                    >
                      Start Free Trial
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="features-section">
          <Container>
            <Row>
              <Col xs="12" lg={{ size: 6, order: 1 }}>
                <div className="features-thumb">
                  <img className="img-fluid" src={ScreenOne} alt="" />
                </div>
              </Col>
              <Col xs="12" lg="6">
                <div className="features-info">
                  <div className="shape-wrap">
                    <div className="shape-wrap-overlay">
                      <img src={OverlayShape} alt="" />
                    </div>
                    <h2 className="section-title">
                      Powerful real estate websites for agents and properties
                    </h2>
                    <p className="section-subtitle">
                      Yardpost enables you to build your brand and run your entire
                      real estate business from the cloud. Say goodbye to
                      templates and hello to building blocks.
                    </p>
                    <a
                      href="https://demo.yardpost.io/editor/demo/home"
                      className="btn btn-cta"
                    >
                      See Live Demo
                      <ArrowRight className="icon" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="features-section grey">
          <Container>
            <Row>
              <Col xs="12" lg="6">
                <div className="features-thumb">
                  <img className="img-fluid" src={ScreenTwo} alt="" />
                </div>
              </Col>
              <Col xs="12" lg="6">
                <div className="features-info">
                  <div className="shape-wrap">
                      <div className="shape-wrap-overlay">
                        <img src={OverlayBlueShape} alt="" />
                      </div>
                    <h2 className="section-title">Powerful CRM</h2>
                    <p className="section-subtitle">
                      Keep track of your current and incoming website leads and add follow-up reminders and notes so that no potential client falls through the cracks. 
                    </p>
                    <a href={signUpUrl} className="btn btn-cta btn-cta-dark">
                      Learn More
                      <ArrowRight fill="#202020" className="icon" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="features-section">
          <Container>
            <Row>
              <Col lg="12">
                <div className="features-thumb  text-center mb-5">
                  <img className="img-fluid" src={ScreenThree} alt="" />
                </div>
              </Col>
              <Col lg="12">
                <div className="features-info text-center">
                  <h2 className="section-title">
                    Publish your listings to all the major marketplaces
                  </h2>
                  <p className="section-subtitle">
                    As one of the first real estate software vendors to offer complete listing distribution, we’ve partnered up and integrated closely with all of the leading online real estate marketplaces, with more being added constantly.
                  </p>
                  <a
                    href={signUpUrl}
                    className="btn btn-cta"
                  >
                    Started Free Trial
                    <ArrowRight className="icon" />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="features-section grey">
          <Container>
            <Row>
              <Col xs="12" lg={{ size: 6, order: 1 }} >
                <div className="features-thumb">
                  <img className="img-fluid" src={ScreenFour} alt="" />
                </div>
              </Col>
              <Col xs="12" lg="6">
                <div className="features-info">
                <div className="shape-wrap">
                      <div className="shape-wrap-overlay">
                        <img src={OverlayShape} alt="" />
                      </div>
                  <h2 className="section-title">
                    Dominate your market using next-generation MLS functionality
                  </h2>
                  <p className="section-subtitle">
                    Connect your site to your MLS so that you can showcase all the listings in your region with your brand prominently displayed. Send your listings to your MLS automatically when you add them to your site.
                  </p>
                  <a
                    href={signUpUrl}
                    className="btn btn-cta"
                  >
                    Started Free Trial
                    <ArrowRight className="icon" />
                  </a>
                </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="features-section">
          <Container>
            <Row className="align-items-center">
              <Col xs="12" lg="6">
                <div className="features-thumb">
                  <img className="img-fluid" src={ScreenFive} alt="" />
                </div>
              </Col>
              <Col xs="12" lg="6">
                <div className="features-info">
                 <div className="shape-wrap">
                      <div className="shape-wrap-overlay">
                        <img src={OverlayShape} alt="" />
                      </div>
                  <h2 className="section-title">Create forms that convert</h2>
                  <p className="section-subtitle">
                    Yardpost's drag-and-drop form builder makes it easy to build
                    lead-generating contact forms, home affordability and market
                    value requests, rental applications and more
                  </p>
                  <a
                    href={signUpUrl}
                    className="btn btn-cta btn-cta-dark"
                  >
                    Learn More
                    <ArrowRight fill="#202020" className="icon" />
                  </a>
                </div>
                </div>
              </Col>
              
            </Row>
          </Container>
        </section>
        <section className="mls-features-section">
          <Container>
            <Row>
              <Col md="12">
                <div className="mls-features">
                  <div className="mls-heading">
                    <p className="mls-heading-title">
                      We have over 40+ MLS connections and growing
                    </p>
                  </div>
                  <Row>
                    <Col md="4">
                      <ul className="mls-listing">
                        <li>ACTRIS Reference Server</li>
                        <li>Alaska Multiple Listing Service</li>
                        <li>Arizona Regional Multiple Listing Service</li>
                        <li>Austin Board of REALTORS®</li>
                        <li>Cape Cod & Islands MLS</li>
                        <li>Central Jersey MLS</li>
                        <li>Central Oregon Association of REALTORS®</li>
                        <li>Combined L.A./Westside MLS</li>
                        <li>First Multiple Listing Service, Inc.</li>
                        <li>Georgia MLS</li>
                        <li>Greater El Paso Association of REALTORS®</li>
                        <li>Greater McAllen Association of REALTORS®</li>
                        <li>
                          Gulf Coast MLS / Mobile Area Association of REALTORS®
                        </li>
                        <li>Hawaii Information Service</li>
                        <li>Houston Association of REALTORS®</li>
                        <li>Idyllwild AOR</li>
                        <li>Intermountain MLS</li>
                        <li>IRES MLS</li>
                      </ul>
                    </Col>
                    <Col md="4">
                      <ul className="mls-listing">
                        <li>Jackson Area Association of REALTORS®</li>
                        <li>Martha's Vineyard MLS</li>
                        <li>Greater El Paso Association of REALTORS®</li>
                        <li>Greater McAllen Association of REALTORS®</li>
                        <li>
                          Gulf Coast MLS / Mobile Area Association of REALTORS®
                        </li>
                        <li>Hawaii Information Service</li>
                        <li>Houston Association of REALTORS®</li>
                        <li>Idyllwild AOR</li>
                        <li>Intermountain MLS</li>
                        <li>IRES MLS</li>
                        <li>Jackson Area Association of REALTORS®</li>
                        <li>Martha's Vineyard MLS</li>
                        <li>Miami Association of REALTORS®</li>
                        <li>MLS Now</li>
                        <li>MLS Property Information Network</li>
                        <li>Naples Area Board of REALTORS®</li>
                        <li>Northeast Georgia Board of Realtors</li>
                        <li>Northeast Louisiana Association of REALTORS®</li>
                      </ul>
                    </Col>
                    <Col md="4">
                      <ul className="mls-listing">
                        <li>NY State MLS</li>
                        <li>Oakville, Milton and District Real Estate Board</li>
                        <li>Okanagan Mainline Real Estate Board</li>
                        <li>OneKey</li>
                        <li>Park City Board of REALTORS®</li>
                        <li>Pensacola Association of REALTORS®</li>
                        <li>Realtor® Association of Acadiana</li>
                        <li>REALTORS® Association of Edmonton</li>
                        <li>
                          Rhode Island Association of REALTORS® & State-Wide MLS
                        </li>
                        <li>San Diego MLS</li>
                        <li>San Francisco Association of REALTORS®</li>
                        <li>SmartMLS</li>
                        <li>Stellar MLS</li>
                        <li>Triad MLS</li>
                        <li>Triangle Multiple Listing Services</li>
                        <li>Valley MLS</li>
                        <li>Victoria and Vancouver Island</li>
                      </ul>
                    </Col>
                  </Row>
                  <div className="mls-feature-footer">
                    <button
                      className="btn btn-request-connection"
                      onClick={this.handleOpenModal}
                    >
                      Request Connection
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="start-trial-section">
          <Container>
            <Row>
              <Col lx="12" lg="12" md="12" sm="12" xs="12">
                <div className="start-trial-wrapper">
                  <h2 className="title">Start your free 14-day trial now</h2>
                  <a
                    href={signUpUrl}
                    className="btn btn-start-trail"
                  >
                    Get Started
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="bottom-section">
          <Container>
            <Row>
              <Col sm="12" md="12">
                <div className="bottom-section-inner">
                  <div className="bottom-info">
                    <h2>Get A Closer's Tool-Kit</h2>
                    <p>
                      Get started with Yarpost. You don’t need technical or
                      design skills to have a beautiful site.
                    </p>
                  </div>
                  <div className="bottom-cta">
                    <a
                      href={signUpUrl}
                      className="btn btn-trial"
                    >
                      Get Started with 14-day free trial
                    </a>
                    <a
                      href="https://demo.yardpost.io/editor/demo/home"
                      className="btn btn-see-live-demo"
                    >
                      See Live Demo
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}

        <Footer />
        <ReactModal
          isOpen={this.state.requestMlsModal}
          onRequestClose={this.handleCloseModal}
          contentLabel="Request MLS"
          ariaHideApp={false}
          portalClassName="react-modal"
          overlayClassName="modal"
          className="modal-dialog modal-dialog-centered"
        >
          <div className="modal-content">
            <div className="react-modal-header">
              <h5 className="react-modal-title">Request MLS </h5>
              <button
                type="button"
                className="btn react-modal-close"
                onClick={this.handleCloseModal}
              >
                <img src={CloseIcon} />
              </button>
            </div>
            <div className="react-modal-body ">
              <Row>
                <Col lx="6" lg="6" md="12" sm="12" xs="12">
                  <div className="material_input">
                    <label className="pure-material-textfield-outlined">
                      <input
                        value={this.state.MLSFirstName}
                        onChange={e =>
                          this.setState({
                            MLSFirstName: e.target.value,
                            "error.firstName": ""
                          })
                        }
                        type="text"
                        className="form-control"
                        name="firstName"
                        placeholder=" "
                        required
                      />
                      <span className="input__placeholder">First Name</span>
                    </label>
                    {get(error, "firstName", false) && (
                      <div className="text-danger">{error.firstName}</div>
                    )}
                  </div>
                </Col>
                <Col lx="6" lg="6" md="12" sm="12" xs="12">
                  <div className="material_input">
                    <label className="pure-material-textfield-outlined">
                      <input
                        value={this.state.MLSLastName}
                        onChange={e =>
                          this.setState({
                            MLSLastName: e.target.value,
                            "error.lastName": ""
                          })
                        }
                        type="text"
                        className="form-control"
                        name="firstName"
                        placeholder=" "
                        required
                      />
                      <span className="input__placeholder">Last Name</span>
                    </label>
                    {get(error, "lastName", false) && (
                      <div className="text-danger">{error.lastName}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lx="6" lg="6" md="12" sm="12" xs="12">
                  <div className="material_input">
                    <label className="pure-material-textfield-outlined">
                      <input
                        value={this.state.MLSBrokerageName}
                        onChange={e =>
                          this.setState({
                            MLSBrokerageName: e.target.value,
                            "error.MLSBrokerageName": ""
                          })
                        }
                        type="text"
                        className="form-control"
                        name="firstName"
                        placeholder=" "
                        required
                      />
                      <span className="input__placeholder">Brokerage Name</span>
                    </label>
                    {get(error, "MLSBrokerageName", false) && (
                      <div className="text-danger">
                        {error.MLSBrokerageName}
                      </div>
                    )}
                  </div>
                </Col>
                <Col lx="6" lg="6" md="12" sm="12" xs="12">
                  <div className="material_input">
                    <label className="pure-material-textfield-outlined">
                      <input
                        value={this.state.MLSEmail}
                        onChange={e =>
                          this.setState({
                            MLSEmail: e.target.value,
                            "error.email": ""
                          })
                        }
                        type="text"
                        className="form-control"
                        name="firstName"
                        placeholder=" "
                        required
                      />
                      <span className="input__placeholder">Email </span>
                    </label>
                    {get(error, "email", false) && (
                      <div className="text-danger">{error.email}</div>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="material_input">
                <label className="pure-material-textfield-outlined">
                  <input
                    value={this.state.yourMLs}
                    onChange={e =>
                      this.setState({
                        yourMLs: e.target.value,
                        "error.yourMLs": ""
                      })
                    }
                    type="text"
                    className="form-control"
                    name="firstName"
                    placeholder=" "
                    required
                  />
                  <span className="input__placeholder">Your MLS </span>
                </label>
                {get(error, "yourMLs", false) && (
                  <div className="text-danger">{error.yourMLs}</div>
                )}
              </div>
              <button
                onClick={() => this.onRequestMLS()}
                className="btn btn-dark btn-block btn-request"
              >
                Request
              </button>
              {successMessage !== "" && (
                <div className="text-success">{successMessage}</div>
              )}
            </div>
          </div>
        </ReactModal>
      </div>
    )
  }
}

export default Features
